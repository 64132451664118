<template>
    <div class="center">
        <h1 class="center">用户统计页</h1>
        <div id="pieReport" style="width: 450px;height: 501px;display:inline-block;"></div>
        <div class="census" style="display:inline-block;width:500px;margin-left:100px">
            <el-table :data="opinionData" show-summary style="width:100%">
                <el-table-column prop="name" label="学科领域"></el-table-column>
                <el-table-column prop="value" label="用户人数"></el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import echarts from 'echarts';
import {QUERY} from '@/services/dao.js';
import {get} from '@/services/http.js';
export default {
    data(){
        return{
            charts: "",
            opinion: [
                "计算机科学", 
                "材料科学",
                "电工电子",
                "教育学",
                "图书情报",
                "合成生物学",
                "人工智能",
                "机械工程",
                "其他",
                ],
            opinionData: [
                { value: 301355, name: "计算机科学", itemStyle: "rgba(255,165,0,0.8)" },
                { value: 299717, name: "材料科学", itemStyle: "rgba(255,0,0,0.8)" },
                { value: 601652, name: "电子电工", itemStyle: "rgba(169,169,169,0.8)" },
                { value: 1, name: "教育学", itemStyle: "rgba(0,255,0,0.8)" },
                { value: 301131, name: "图书情报", itemStyle: "rgba(0,127,255,0.8)" },
                { value: 301789, name: "合成生物学", itemStyle: "rgba(0,0,255,0.8)" },
                { value: 300256, name: "人工智能", itemStyle: "rgba(139,0,255,0.8)" },
                { value: 301769, name: "机械工程", itemStyle: "rgba(255,127,80,0.8)" },
                { value: 299664, name: "其他", itemStyle: "rgba(210,180,140,0.8)" },
            ],
        }
    },
    created(){
        this.initUserData();
    },
    methods:{
        // 用户统计页方法
        drawPie(id) {
            // this.charts = this.$echarts.init(document.getElementById(id));
            this.charts = echarts.init(document.getElementById(id));

            this.charts.setOption({
                tooltip: {
                trigger: "item",
                formatter: "{a}<br/>{b}:{c} ({d}%)"
                },
                legend: {
                bottom: 10,
                left: "center",
                data: this.opinion
                },
                series: [
                {
                    name: "状态",
                    type: "pie",
                    radius: "65%",
                    center: ["50%", "50%"],
                    avoidLabelOverlap: true,
                    itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)"
                    },
                    color: function(params) {
                        //自定义颜色
                        var colorList = [
                            "rgba(255,165,0,0.8)", 
                            "rgba(255,0,0,0.8)",
                            "rgba(169,169,169,0.8)",
                            "rgba(0,255,0,0.8)",
                            "rgba(0,127,255,0.8)",
                            "rgba(0,0,255,0.8)",
                            "rgba(139,0,255,0.8)",
                            "rgba(255,127,80,0.8)",
                            "rgba(210,180,140,0.8)",
                        ];
                        return colorList[params.dataIndex];
                    }
                    },
                    data: this.opinionData
                }
                ]
            });
        },
        async initUserData(){
            // if(num>this.opinionData.length-1)return
            // let obj = this.opinionData[num]
            // let data = await QUERY(
            //     "POST","", 'PCUsers_aggregate(where:{industry:{_eq:"'+obj.name+'"}}) {aggregate{count}}'
            // );
            // let count = data.data.PCUsers_aggregate.aggregate.count
            // console.log(num,count);
            // this.$set(obj,'value',count)
            // num++
            // this.initUserData(num)
            //获取数据
            get('/common/getUserNum',{}).then(res=>{
                console.log(res.data);
                // opinionData
                if(res.success){
                    for(let item of this.opinionData){
                        let value = res.data[item.name];
                        this.$set(item,'value',value)
                    }
                    this.drawPie("pieReport"); 

                }
            })
        }
    },
    // mounted() {
        // this.$nextTick(function() {
        //     this.drawPie("pieReport"); 
        // });
    // },
}
</script>
<style scoped>
/* 待定页面二样式 */
/* public style */
.center{
    text-align: center;
}
.line-center{
    margin: 0 auto;
}
.wid-perc90{
    width: 90%;
}
/* private style */
.census{
    /* float: right; */
}

/* reset style */
</style>